export const groupLandCoveringVisuals = [
  {
    name: "Demo",
    markdown: `![Group land covering GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const groupLandCovering = {
  order: 19,
  name: "Group land coverings",
  keywords: "connected buildings",
  subcategory: "Properties",
  markdown: `# Group land coverings

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  Due to the complexities inherent in certain kinds of properties, there are situations where it makes sense to represent a single physical structure as multiple {% inlineRouterLink %}land coverings{% /inlineRouterLink %} in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}. When this happens, you can group these related structures to convey that they are part of a larger whole.

  {% callout type="tip" %}
  **Tip:** To use this feature there must already be multiple land coverings in the property diagram.
  {% /callout %}

  ## When to use

  Here are some situations where it makes sense to group related land coverings:

  - Multiple buildings (possibly of different heights) are physically connected via a common area or atrium. {% inlineRouterLink %}See an example{% /inlineRouterLink %}.
  - A single building features a large architectural opening (ex. an archway) that you want to represent in the diagram (using {% inlineRouterLink %}horizontal building level alignment{% /inlineRouterLink %}). {% inlineRouterLink %}See an example{% /inlineRouterLink %}.
  - A single building is internally divided into different operational units (often with separate entrances) that may be controlled by separate parties via {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land covering ownership units (optional)" %}ownership units{% /inlineRouterLink %}. {% inlineRouterLink %}See an example{% /inlineRouterLink %}.

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Property selector" %}property{% /inlineRouterLink %} or {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}land covering{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addLandCoveringGrouping" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}land coverings{% /inlineRouterLink %} to group.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. A (selectable) colored stripe appears at the top of each grouped {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}land covering{% /inlineRouterLink %}.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$groupLandCoveringVisuals /%}
  `,
};
